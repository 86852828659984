import React, { useState, useEffect } from "react";
import { css, Theme } from "@emotion/react";
import { useAppSelector, useAppDispatch } from "redux/hooks/hooks";
import {
  handleInput,
  selectInputComment,
  selectInputUsername,
} from "redux/slice/chatInput/chatInputSlice";
import { selectEmojiData } from "redux/slice/emoji/emojiSlice";
import SendIcon from "@material-ui/icons/Send";
import IconButton from "@material-ui/core/IconButton";
import EmojiEmotionsOutlinedIcon from "@material-ui/icons/EmojiEmotionsOutlined";
import InputText from "components/InputText";
import { SendProps } from "hooks/useWebSocket";
import { SearchParams } from "util/searchParams";

const areaStyle = (theme: Theme) => css`
  position: relative;
  padding: ${theme.customizeTheme === "gundam" ? "12px 20px" : "8px 16px"};
  border-top: 1px solid ${theme.palette.divider};
  box-sizing: border-box;
  background: ${theme.customizeTheme === "gundam"
    ? "#2d3440"
    : theme.palette.background.default};
  z-index: 100;
`;
const inputGroupStyle = (theme: Theme) => css`
  position: relative;
  display: flex;
  align-items: center;
  &:first-of-type {
    margin-bottom: ${theme.customizeTheme === "gundam"
      ? 12
      : theme.spacing(1)}px;
  }
`;
const hiddenStyle = css`
  display: none;
`;
const buttonStyle = (theme: Theme) => css`
  margin: 0 0 0 4px;
  width: ${theme.customizeTheme === "gundam" ? 30 : 32}px;
  height: ${theme.customizeTheme === "gundam" ? 30 : 32}px;
  padding: 0;
  color: ${theme.palette.primary.main};
`;

type Props = {
  wsSend: ({ username, comment, isModerator }: SendProps) => void;
};
const SendArea: React.FC<Props> = ({ wsSend }) => {
  const dispatch = useAppDispatch();
  const emojiData = useAppSelector(selectEmojiData);
  const comment = useAppSelector(selectInputComment);
  const username = useAppSelector(selectInputUsername);
  const [isName, setIsName] = useState(false);
  const [inputAlert, setInputAlert] = useState({
    comment: false,
    username: false,
  });

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(handleInput({ [e.currentTarget.name]: e.currentTarget.value }));
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const alertCheck = () => {
      const commentIsNull = comment === "";
      const usernameIsNull = username === "";

      let alert = {
        comment: false,
        username: false,
      };

      // input が空の時アラート
      if (commentIsNull || usernameIsNull) {
        alert = {
          comment: commentIsNull,
          username: usernameIsNull,
        };
      }

      return alert;
    };

    // alert がある時は表示してそれ以降の処理を行わない
    const alert = alertCheck();
    if (alert.comment || alert.username) {
      setInputAlert(alert);
      return;
    }
    const role = SearchParams.getParam("role");
    const isModerator = role === "moderator";

    wsSend({ username: username, comment: comment, isModerator: isModerator });
    dispatch(handleInput({ comment: "" }));
    setInputAlert({
      comment: false,
      username: false,
    });

    // 初期ニックネームを登録したら ニックネーム欄を消す
    setIsName(true);
  };

  useEffect(() => {
    const user = SearchParams.getParam("user");
    if (user) {
      setIsName(true);
      dispatch(handleInput({ username: user }));
    }

    const role = SearchParams.getParam("role");
    if (role === "moderator") {
      dispatch(handleInput({ username: "【公式】" }));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div css={areaStyle}>
      <form onSubmit={onSubmit}>
        <div css={[inputGroupStyle, isName && hiddenStyle]}>
          <InputText
            name="username"
            text={username}
            placeholder="ニックネーム（初回のみ）"
            isAlert={inputAlert.username}
            onChange={onChangeInput}
          />
        </div>
        <div css={inputGroupStyle}>
          <InputText
            name="comment"
            text={comment}
            placeholder="コメント"
            isAlert={inputAlert.comment}
            onChange={onChangeInput}
          />
          {emojiData.length > 0 && (
            <IconButton id="js-emoji-trigger" css={buttonStyle} type="button">
              <EmojiEmotionsOutlinedIcon />
            </IconButton>
          )}
          <IconButton css={buttonStyle} type="submit">
            <SendIcon />
          </IconButton>
        </div>
      </form>
    </div>
  );
};

export default SendArea;
