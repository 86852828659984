import React, { useEffect, useState, useRef } from "react";
import { css, Theme } from "@emotion/react";
import { useAppSelector, useAppDispatch } from "redux/hooks/hooks";
import {
  setComments,
  selectComments,
  selectLatestComment,
} from "redux/slice/chatComment/chatCommentSlice";
import { selectEmojiData } from "redux/slice/emoji/emojiSlice";
import useFetch from "hooks/useFetch";
import useTargetInView from "hooks/useTargetInView";
import useCreateEmojiMap from "hooks/useCreateEmojiMap";
import useScrollToTarget from "hooks/useScrollToTarget";
import { SearchParams } from "util/searchParams";
import { filterFromFormatCommnets } from "util/filterComments";
import { getTimeStamp } from "util/getTimeStamp";
import { getRandomColorByString } from "util/getRandomColorByString";
import { Props as ChatTextProps } from "components/ChatText";
import { Props as ChatTextGundamProps } from "components/ChatTextGundam";
import { Props as ChatTextPickUpProps } from "components/ChatTextPickup";
import { Props as ChatTextPickUpGundamProps } from "components/ChatTextPickupGundam";
import { Props as ScrollArrowProps } from "components/ScrollArrow";
import { Props as ScrollArrowGundamProps } from "components/ScrollArrowGundam";

const wrapperStyle = css`
  position: relative;
  flex-grow: 1;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 50;
`;
const mainStyle = css`
  height: 100%;
  overflow-x: hidden;
  scrollbar-width: 10px;
`;
const containerStyle = css`
  padding: 16px;
  position: relative;
`;
const scrollTargetStylle = (theme: Theme) => css`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: ${theme.spacing(12)}px;
  opacity: 0;
  visibility: hidden;
`;

// parameter
const limit = SearchParams.getParam("limit") || "10";
const ChatApiUrl = `${
  process.env.REACT_APP_API_ENDPOINT
}/v1/comments/${SearchParams.getParam("room")}?limit=${limit}&filter=true`;
const colorful = SearchParams.getParam("colorful") === "true";

type Props = {
  chattext: React.FC<ChatTextProps | ChatTextGundamProps>;
  chattextpickup: React.FC<ChatTextPickUpProps | ChatTextPickUpGundamProps>;
  scrollArrow: React.FC<ScrollArrowProps | ScrollArrowGundamProps>;
};
const ChatArea: React.FC<Props> = ({
  chattext,
  chattextpickup,
  scrollArrow,
}) => {
  const dispatch = useAppDispatch();
  const comments = useAppSelector(selectComments);
  const commentsRef = useRef<typeof comments>([]);
  const latestComment = useAppSelector(selectLatestComment);
  const emojiData = useAppSelector(selectEmojiData);
  const emojiMap = useCreateEmojiMap(emojiData);
  const autoScrollEnableTargetRef = useRef<HTMLDivElement | null>(null);
  const { inView } = useTargetInView(autoScrollEnableTargetRef);
  const outerScrollElementRef = useRef<HTMLDivElement | null>(null);
  const innerScrollElementRef = useRef<HTMLDivElement | null>(null);
  const [isTimeStamp, setIsTimeStamp] = useState(true);
  const [commentPickUp, setCommentPickUp] = useState({
    is: false,
    username: "",
    comment: "",
    timestamp: "",
  });
  const ChatTextComponet = chattext;
  const ScrollArrowComponent = scrollArrow;
  const ChatTextPickupComponent = chattextpickup;

  // コメントの初回取得
  type ChatMessage = {
    comment_id: string;
    username: string;
    message: string;
    timestamp: number;
    user_id: string;
    badge_url?: string;
    is_moderator: boolean;
    is_blocked: boolean;
  };
  const { fetched, res: chatResponse } = useFetch<{
    comments: ChatMessage[] | null;
  }>(ChatApiUrl);

  const { onScrollClick } = useScrollToTarget(
    autoScrollEnableTargetRef,
    outerScrollElementRef,
    innerScrollElementRef,
    inView,
    fetched
  );

  // 初回マウント時の処置
  useEffect(() => {
    const filterCheck = async () => {
      const currentCommnets = [...commentsRef.current];
      const formatComments = await filterFromFormatCommnets(currentCommnets);
      dispatch(setComments(formatComments));
    };

    // 定期的にコメント削除チェック
    const timerid = setInterval(() => {
      filterCheck();
    }, 60000);

    // タイムスタンプの表示
    const ts = SearchParams.getParam("timestamp") !== "false";
    setIsTimeStamp(ts);

    return () => {
      clearInterval(timerid);
    };
    // eslint-disable-next-line
  }, []);

  // After fetched
  useEffect(() => {
    if (!fetched) return;
    if (!chatResponse) return;

    const chatComments = chatResponse.comments ? chatResponse.comments : [];
    const formatComments = chatComments.map(v => {
      const timestamp = getTimeStamp(v.timestamp);
      const color = getRandomColorByString(v.username);
      const badge = v.badge_url !== undefined ? { badgeUrl: v.badge_url } : {};
      return {
        commentId: v.comment_id,
        username: v.username,
        comment: v.message,
        timestamp: timestamp,
        color: color,
        isModerator: v.is_moderator,
        ...badge,
      };
    });
    dispatch(setComments(formatComments));
    // eslint-disable-next-line
  }, [fetched]);

  // commnets update用
  useEffect(() => {
    commentsRef.current = comments;
  }, [comments]);

  //　コメントが更新された時の処理
  useEffect(() => {
    if (Object.keys(latestComment).length === 0) return;

    // moderatorの時だけ更新
    if (latestComment.isModerator) {
      setCommentPickUp({
        is: true,
        username: latestComment.username,
        comment: latestComment.comment,
        timestamp: latestComment.timestamp,
      });
    }
    // eslint-disable-next-line
  }, [comments.length]);

  // ピックアップコメントが更新された時の処理
  useEffect(() => {
    let timerid: NodeJS.Timer | undefined;
    if (commentPickUp.is) {
      timerid = setTimeout(() => {
        setCommentPickUp(pre => ({
          ...pre,
          is: false,
        }));
      }, 10000);
    }
    return () => {
      if (timerid) {
        clearTimeout(timerid);
      }
    };
  }, [commentPickUp]);

  const commentsOver = comments.length - 300;

  return (
    <div css={wrapperStyle}>
      <div ref={outerScrollElementRef} css={mainStyle}>
        <div ref={innerScrollElementRef} css={containerStyle}>
          {emojiMap && Object.keys(latestComment).length > 0 && (
            <ChatTextPickupComponent
              show={commentPickUp.is}
              comment={commentPickUp.comment}
              username={commentPickUp.username}
              timestamp={commentPickUp.timestamp}
              emojiMap={emojiMap}
            />
          )}
          {emojiMap &&
            comments.map((v, i) => {
              if (i > commentsOver) {
                return (
                  <ChatTextComponet
                    key={i.toString()}
                    comment={v.comment}
                    username={v.username}
                    isModerator={v.isModerator}
                    timestamp={v.timestamp}
                    isTimeStamp={isTimeStamp}
                    color={colorful ? v.color : "default"}
                    emojiMap={emojiMap}
                    badge={v.badgeUrl}
                  />
                );
              }
              return null;
            })}
          <div css={scrollTargetStylle} ref={autoScrollEnableTargetRef}></div>
        </div>
      </div>
      <ScrollArrowComponent show={!inView} onClick={onScrollClick} />
    </div>
  );
};

export default ChatArea;
