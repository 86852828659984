import indigo from "@material-ui/core/colors/indigo";
import blue from "@material-ui/core/colors/blue";
import { ThemeOptions } from "@material-ui/core";
import { SearchParams } from "util/searchParams";

const colorType = SearchParams.getParam("mode") === "dark" ? "dark" : "light";

const def: ThemeOptions = {
  customizeTheme: "def",
  palette: {
    type: colorType,
    primary: colorType === "dark" ? blue : indigo,
  },
  typography: {
    fontFamily: `"Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN", "Hiragino Sans", "ヒラギノ角ゴシック", "Meiryo", "メイリオ", sans-serif`,
    size: (n: number) => n * 4,
  },
};
const gundam: ThemeOptions = {
  ...def,
  customizeTheme: "gundam",
  palette: {
    type: colorType,
    primary: {
      main: colorType === "dark" ? "#fff" : "#000",
    },
    background: {
      default: "#363f4d",
      paper: "#4a535f"
    }
  },
  typography: {
    fontFamily: `"Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN", "Yu Gothic", "YuGothic", "游ゴシック", "游ゴシック体",sans-serif`,
    size: (n: number) => n * 4,
  },
};

export const theme = {
  def,
  gundam
};