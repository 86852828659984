import axios from "axios";
import { SearchParams } from "util/searchParams";
import { Comment } from "redux/slice/chatComment/chatCommentSlice"

export const filterFromFormatCommnets = async (data: Comment[]) => {
  const deleteList: string[] = await axios
    .get<any>(
      `${process.env.REACT_APP_API_ENDPOINT}/rooms/${SearchParams.getParam(
        "room"
      )}/deleted_comments`
    )
    .then(res => res.data.List)
    .catch(e => {
      console.error(e);
    });

  const deleteSet = new Set(deleteList);
  const reducer = (acc: Comment[], cur: Comment) => {
    if (deleteSet.has(cur.commentId)) return acc;
    const badge = cur.badgeUrl !== undefined ? { badgeUrl: cur.badgeUrl } : {};
    const comment = {
      commentId: cur.commentId,
      username: cur.username,
      comment: cur.comment,
      timestamp: cur.timestamp,
      color: cur.color,
      isModerator: cur.isModerator,
      ...badge,
    };
    acc.push(comment);
    return acc;
  };

  return data.reduce(reducer, []);
};
