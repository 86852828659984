import React from "react";
import { css, Theme } from "@emotion/react";

const disableStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const text = (theme: Theme) => css`
  color: ${theme.palette.text.primary};
  font-size: ${theme.typography.size(4)}px;
  font-weight: ${theme.typography.fontWeightBold};
`;

const DisableContents: React.FC = () => {
  return (
    <div css={disableStyle}>
      <p css={text}>チャットは無効です。</p>
    </div>
  );
};

export default DisableContents;
