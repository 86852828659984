import { configureStore } from "@reduxjs/toolkit";
import chatCommentReeducer from "redux/slice/chatComment/chatCommentSlice";
import chatInputtReducer from "redux/slice/chatInput/chatInputSlice";
import emojiReducer from "redux/slice/emoji/emojiSlice";
import themeReducer from "redux/slice/theme/theme";

export const store = configureStore({
  reducer: {
    chatComment: chatCommentReeducer,
    chatInput: chatInputtReducer,
    emoji: emojiReducer,
    theme: themeReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
