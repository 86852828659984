import { useState, useEffect } from "react";
const useTargetInView = (
  el: React.MutableRefObject<HTMLDivElement | null>
) => {
  const [inView, setInView] = useState(true);

  useEffect(() => {
    if (!el.current) return;
    const cb = (entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          setInView(true);
        } else {
          setInView(false);
        }
      });
    };
    const observer = new IntersectionObserver(cb);
    observer.observe(el.current);
  }, [el]);

  return { inView };
};

export default useTargetInView;
