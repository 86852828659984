import React from 'react';
import { css, Theme } from '@emotion/react';
import { useAppDispatch } from 'redux/hooks/hooks';
import { setEmojiData, Emoji } from 'redux/slice/emoji/emojiSlice';
import { useFetchEmoji } from 'hooks/useFetchEmoji';
import { SearchParams } from 'util/searchParams';
import EmojiItem from './EmojiItem';

const pickerStyle = (theme: Theme) => css`
  position: fixed;
  right: 28px;
  bottom: ${theme.spacing(6)}px;
  background: ${theme.palette.background.paper};
  max-width: 259px;
  padding: ${theme.spacing(1)}px;
  border-radius: 5px;
  box-shadow: ${theme.shadows[2]};
  &::before {
    content: '';
    position: absolute;
    bottom: -6px;
    right: 34px;
    width: 12px;
    height: 12px;
    background: ${theme.palette.background.paper};
    transform-origin: center center;
    transform: rotate(45deg);
    box-shadow: ${theme.shadows[2]};
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${theme.palette.background.paper};
    border-radius: 5px;
  }
  z-index: 100;
  > * {
    position: relative;
    z-index: 1;
  }
`;
const hiddenStyle = css`
  opacity: 0;
  visibility: hidden;
`;
const containerStyle = css`
  position: relative;
`;
const galleryStyle = css`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

type JsonType = {
  emoji: Emoji[] | null;
};
type Props = {
  hidden: boolean;
};

const EmojiPicker: React.FC<Props> = ({ hidden }) => {
  const dispatch = useAppDispatch();
  const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/rooms/${SearchParams.getParam(
    'room'
  )}/emoji`;

  const { res } = useFetchEmoji<JsonType>(url, res => {
    const emojiData = res?.emoji !== null ? res.emoji : [];
    dispatch(setEmojiData({ emojiData: emojiData }));
  });
  const emoji = res?.emoji;

  return (
    <div
      id="js-emoji-picker"
      aria-hidden={hidden}
      css={[pickerStyle, hidden && hiddenStyle]}
    >
      <div css={containerStyle}>
        <ul css={galleryStyle}>
          {emoji &&
            emoji.length > 0 &&
            emoji.map((v) => (
              <EmojiItem
                key={v.id}
                label={v.label}
                url={v.url}
                name={v.name}
              />
            ))}
        </ul>
      </div>
    </div>
  );
};

export default EmojiPicker;
