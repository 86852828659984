import React, { Suspense } from "react";
import { css } from "@emotion/react";
import { useAppSelector } from "redux/hooks/hooks";
import { selectTheme } from "redux/slice/theme/theme";
import useEmojiPicker from "hooks/useEmojiPicker";
import useWebSocket from "hooks/useWebSocket";
import { getWebSocketURL } from "util/getWebSocketURL";
import Header from "components/Header";
import ChatArea from "components/ChatArea";
import SendArea from "components/SendArea";
import EmojiPicker from "components/EmojiPicker";
import Loading from "components/Loading";
// theme パラメータで切り替えるときに違うコンポーネントを読み込まないように
// パフォーマンス上の理由
const ChatText = React.lazy(() => import("components/ChatText"));
const ChatTextGundam = React.lazy(() => import("components/ChatTextGundam"));
const ChatTextPickup = React.lazy(() => import("components/ChatTextPickup"));
const ChatTextPickupGundam = React.lazy(
  () => import("components/ChatTextPickupGundam")
);
const ScrollArrow = React.lazy(() => import("components/ScrollArrow"));
const ScrollArrowGundam = React.lazy(
  () => import("components/ScrollArrowGundam")
);

const containerStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;
const loading = css`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 200;
  background: rgba(0, 0, 0, 0.3);
`;

const websocketUrl = getWebSocketURL();
const ChatAreaSwitch = {
  def: {
    chattext: ChatText,
    chattextpickup: ChatTextPickup,
    scrollArrow: ScrollArrow,
  },
  gundam: {
    chattext: ChatTextGundam,
    chattextpickup: ChatTextPickupGundam,
    scrollArrow: ScrollArrowGundam,
  },
};

const Contents: React.FC = () => {
  const theme = useAppSelector(selectTheme) as keyof typeof ChatAreaSwitch;
  const { hidden, onClick } = useEmojiPicker();
  const { error, send } = useWebSocket(websocketUrl);

  return (
    <div css={containerStyle} onClick={onClick}>
      <Header />
      <Suspense fallback={<Loading />}>
        <ChatArea
          chattext={ChatAreaSwitch[theme].chattext}
          chattextpickup={ChatAreaSwitch[theme].chattextpickup}
          scrollArrow={ChatAreaSwitch[theme].scrollArrow}
        />
      </Suspense>
      <SendArea wsSend={send} />
      <EmojiPicker hidden={hidden} />
      {error && (
        <div css={loading}>
          <Loading />
        </div>
      )}
    </div>
  );
};

export default React.memo(Contents);
