import { useState } from "react";

const useEmojiPicker = () => {
  const [hidden, setHidden] = useState(true);

  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLElement;
    const picker = target.closest("#js-emoji-picker");
    const trigger = target.closest("#js-emoji-trigger");

    if (!picker) {
      if (trigger) {
        setHidden(prev => !prev);
      } else {
        !hidden && setHidden(true);
      }
    }
  };

  return { hidden, onClick };
};

export default useEmojiPicker;
