import { useEffect, useCallback, useState } from "react";
import { useAppSelector } from "redux/hooks/hooks";
import {
  selectLatestComment,
  selectMyLatestComment,
} from "redux/slice/chatComment/chatCommentSlice";
import { selectInputFocus } from "redux/slice/chatInput/chatInputSlice";
import { scrollElement } from "util/scrollElement";

const useScrollToTarget = (
  ref: React.MutableRefObject<HTMLElement | null>,
  outerRef: React.MutableRefObject<HTMLDivElement | null>,
  innerRef: React.MutableRefObject<HTMLDivElement | null>,
  inView: boolean,
  fetched: boolean
) => {
  const latestComment = useAppSelector(selectLatestComment);
  const myComment = useAppSelector(selectMyLatestComment);
  const focus = useAppSelector(selectInputFocus);
  const [firstScroll, setFirstScroll] = useState(false);

  const onScrollClick = useCallback(() => {
    if (!ref.current) return;
    scrollElement(outerRef, innerRef);
    // eslint-disable-next-line
  }, [ref]);

  //　コメントが更新された時の処理
  useEffect(() => {
    if (Object.keys(latestComment).length === 0 || !firstScroll) return;

    // 自分がコメントしたときは常にスクロールする
    if (myComment.commentId === latestComment.commentId) {
      if (!ref.current) return;
      scrollElement(outerRef, innerRef);
    }

    if (inView && !focus) {
      if (!ref.current) return;
      scrollElement(outerRef, innerRef);
    }
    // eslint-disable-next-line
  }, [latestComment]);

  useEffect(() => {
    if (!ref.current || !fetched) return;
    scrollElement(outerRef, innerRef);
    // スクロールしない時があるので、少し待って再実行する
    const timerid = setTimeout(() => {
      if (!ref.current) return;
      scrollElement(outerRef, innerRef);
      setFirstScroll(true);
    }, 300);

    return () => {
      clearTimeout(timerid);
    };
    // eslint-disable-next-line
  }, [ref, fetched]);

  return { onScrollClick };
};

export default useScrollToTarget;
