import React from "react";
import { SearchParams } from "util/searchParams";
import { useAppDispatch } from "redux/hooks/hooks";
import { setTheme } from "redux/slice/theme/theme";
import Index from "page/Index";

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const themeParam = SearchParams.getParam("theme")
    ? SearchParams.getParam("theme")!
    : "def";
  dispatch(setTheme({ theme: themeParam }));

  return <Index />;
};

export default App;
