export type ColorUsername =
  | "red"
  | "purple"
  | "indigo"
  | "lightBlue"
  | "teal"
  | "lightGreen"
  | "yellow"
  | "orange";
export const colorUsername: ColorUsername[] = [
  "red",
  "purple",
  "indigo",
  "lightBlue",
  "teal",
  "lightGreen",
  "yellow",
  "orange",
];
