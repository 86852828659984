import React from "react";
import { css, Theme } from "@emotion/react";
import { useAppDispatch } from "redux/hooks/hooks";
import { handleFocus } from "redux/slice/chatInput/chatInputSlice";
import indigo from "@material-ui/core/colors/indigo";

const inputTextStyle = (theme: Theme) => css`
  border: none;
  background: ${theme.palette.background.paper};
  width: 100%;
  height: ${theme.customizeTheme === "gundam" ? 30 : theme.spacing(4)}px;
  padding: 0 1em;
  box-sizing: border-box;
  border-radius: ${theme.customizeTheme === "gundam" ? 0 : theme.spacing(3)}px;
  color: ${theme.palette.getContrastText(theme.palette.background.default)};
  font-size: ${theme.typography.size(4)}px;
  outline: none;
  box-shadow: 0 0 0 1px ${theme.palette.divider};
  &:focus,
  &:active {
    box-shadow: 0 0 0 1px inset ${indigo[300]};
  }
  &::-webkit-input-placeholder,
  &::placeholder {
    color: ${theme.palette.text.hint};
    font-size: ${theme.typography.size(3)}px;
  }
`;
const errorStyle = (theme: Theme) => css`
  background-color: ${theme.palette.error.light};
`;

type Props = {
  name: string;
  placeholder: string;
  text: string;
  isAlert: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const InputText: React.FC<Props> = ({
  name,
  placeholder,
  text,
  isAlert,
  onChange,
}) => {
  const dispatch = useAppDispatch();
  const onBlur = () => {
    dispatch(handleFocus({ focus: false }));
  };
  const onFocus = () => {
    dispatch(handleFocus({ focus: true }));
  };

  return (
    <input
      css={[inputTextStyle, isAlert && errorStyle]}
      type="text"
      name={name}
      onChange={onChange}
      value={text}
      placeholder={placeholder}
      onFocus={onFocus}
      onBlur={onBlur}
      maxLength={100}
    />
  );
};

export default InputText;
