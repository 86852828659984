import React from "react";
import { css, Theme } from "@emotion/react";

const headerStyle = (theme: Theme) => css`
  position: relative;
  display: flex;
  align-items: center;
  margin: ${theme.customizeTheme === "gundam" ? "10px 20px 0" : "0px"};
  padding: ${theme.customizeTheme === "gundam" ? "4px 0" : "8px 16px"};
  box-sizing: border-box;
  border-bottom: 1px solid
    ${theme.customizeTheme === "gundam" ? "#fff" : theme.palette.divider};
  z-index: 100;
`;
const titleStyle = (theme: Theme) => css`
  font-size: ${theme.customizeTheme === "gundam" ? 15 : 14}px;
  font-weight: ${theme.customizeTheme === "gundam"
    ? 400
    : theme.typography.fontWeightBold};
  line-height: 1.5;
`;

const Header: React.FC = () => (
  <header css={headerStyle}>
    <h1 css={titleStyle}>ライブチャット</h1>
  </header>
);

export default Header;
