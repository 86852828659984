import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { css } from "@emotion/react";

const rootStyle = css`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Loading: React.FC = () => {
  return (
    <div css={rootStyle}>
      <CircularProgress />
    </div>
  );
};

export default Loading;
