import { useState, useEffect } from "react";
import axios from "axios";
import { clearInterval } from "timers";

const useChatEnable = (url: string) => {
  const [enable, setEnable] = useState<boolean | null>(null);

  useEffect(() => {
    const time = 60000; //ms

    const fetchData = async () => {
      const data = await axios(url)
        .then(res => res.data)
        .catch(error => {
          console.error(error);
        });
      // 0 : disable
      // 1 : enable
      setEnable(data === 1);
    };

    const id = setInterval(() => {
      fetchData();
    }, time);
    fetchData();

    return () => {
      clearInterval(id);
    };
    // eslint-disable-next-line
  }, []);

  return { enable };
};

export default useChatEnable;
