import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux/store/store";

export type Emoji = {
  id: number;
  name?: string;
  label: string;
  url: string;
};
const initialState = {
  emojiData: [] as Emoji[],
};

export const emojiSlice = createSlice({
  name: "emoji",
  initialState: initialState,
  reducers: {
    setEmojiData: (state, action) => {
      state.emojiData = action.payload.emojiData;
    },
  },
});

export const { setEmojiData } = emojiSlice.actions;
export const selectEmojiData = (state: RootState) => state.emoji.emojiData;
export default emojiSlice.reducer;
