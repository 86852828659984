import { useState, useEffect } from "react";
import axios from "axios";

export const useFetchEmoji = <T>(url: string, callback?: (res: T) => void) => {
  const [res, setRes] = useState<T | null>(null);
  const [fetched, setFetched] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    (async () => {
      const data: T = await axios({
        method: "GET",
        url: url,
        validateStatus: status => {
          // status: 200番台 もしくは 404 の時に、　then の処理
          // ファイル見つからない時もエラーにしない（空のオブジェクトを返す）
          return (status >= 200 && status < 300) || status === 404;
        },
      })
        .then(res => {
          if (res.status === 404) {
            return {
              path: "/",
              emoji: [],
            };
          }
          return res.data;
        })
        .catch(e => {
          console.error(e);
          setError(true);
        });

    
      if (data){
        setRes(data);
        setFetched(true);
      }

      if (callback !== undefined) {
        callback(data);
      }
    })();
    // eslint-disable-next-line
  }, []);

  return { res, error, fetched };
};
