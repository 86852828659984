import React from "react";
import { css } from "@emotion/react";
import ContentEnableCheck from "components/ContentEnableCheck";
import Contents from "components/Contents";

const wrapperStyle = css`
  width: 100%;
  height: 100%;
`;

const Index: React.FC = () => {
  return (
    <div css={wrapperStyle}>
      <ContentEnableCheck contents={<Contents />} />
    </div>
  );
};

export default Index;
