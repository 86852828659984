import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux/store/store";

export const chatInputSlice = createSlice({
  name: "chatInputText",
  initialState: {
    username: "",
    comment: "",
    focus: false,
  },
  reducers: {
    handleInput: (state, action) => {
      if (action.payload.comment !== undefined) {
        state.comment = action.payload.comment;
      }
      if (action.payload.username !== undefined) {
        state.username = action.payload.username;
      }
    },
    handleFocus: (state, action) => {
      state.focus = action.payload.focus;
    },
  },
});

export const { handleInput, handleFocus } = chatInputSlice.actions;
export const selectInputComment = (state: RootState) => state.chatInput.comment;
export const selectInputUsername = (state: RootState) =>
  state.chatInput.username;
export const selectInputFocus = (state: RootState) => state.chatInput.focus;
export default chatInputSlice.reducer;
