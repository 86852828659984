import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux/store/store";

export type Theme = {
  theme: string;
};
const initialState = {
  theme: "",
};

export const themeSlice = createSlice({
  name: "theme",
  initialState: initialState,
  reducers: {
    setTheme: (state, action) => {
      state.theme = action.payload.theme;
    },
  },
});

export const { setTheme } = themeSlice.actions;
export const selectTheme = (state: RootState) => state.theme.theme;
export default themeSlice.reducer;
