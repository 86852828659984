import React from 'react';
import { css } from '@emotion/react';
import { useAppSelector, useAppDispatch } from 'redux/hooks/hooks';
import {
  handleInput,
  selectInputComment,
} from 'redux/slice/chatInput/chatInputSlice';
import { Emoji } from 'redux/slice/emoji/emojiSlice';
import IconButton from '@material-ui/core/IconButton';

const itemStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  text-align: center;
  box-sizing: border-box;
  &:nth-of-type(n + 8) {
    margin-top: 3px;
  }
`;
const iconStyle = css`
  display: inline-block;
  padding: 5px;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
`;
const imgStyle = css`
  width: 100%;
`;

type Props = Omit<Emoji, 'id'>;
const EmojiItem: React.FC<Props> = ({ label, url, name }) => {
  const dispatch = useAppDispatch();
  const comment = useAppSelector(selectInputComment);

  const itemOnClick = (e: React.MouseEvent<HTMLElement>) => {
    const label = e.currentTarget.dataset.label;
    const nextValue = comment + ':' + label + ':';
    dispatch(handleInput({ comment: nextValue }));
  };

  return (
    <li css={itemStyle}>
      <IconButton css={iconStyle} data-label={label} onClick={itemOnClick}>
        <img css={imgStyle} src={url} alt={name} />
      </IconButton>
    </li>
  );
};

export default EmojiItem;
