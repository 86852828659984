import { colorUsername } from "constants/colorUsername";

export const getRandomColorByString = (str: string) => {
  const reducer = (acc: number, cur: string) => {
    const point = cur.charCodeAt(0);
    return (acc += point);
  };
  const codePoints = str.split("").reduce(reducer, 0);

  const colorLength = colorUsername.length;
  return colorUsername[codePoints % colorLength] 
};
