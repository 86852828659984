import { useState, useEffect } from "react";
import axios from "axios";

const useFetch = <T>(url: string, callback?: (res: T) => void) => {
  const [res, setRes] = useState<T | null>(null);
  const [fetched, setFetched] = useState(false);
  const [error, setError] = useState<any>(null);
  useEffect(() => {
    (async () => {
      const data = await axios
        .get<T>(url)
        .then(res => res.data)
        .catch(e => {
          console.error(e);
          setError(e);
        });

      if (!data) return;
      setRes(data);
      setFetched(true);

      if (callback !== undefined) {
        callback(data);
      }
    })();
    // eslint-disable-next-line
  }, []);

  return { res, error, fetched };
};
export default useFetch;
