import { useEffect, useRef, useState, useCallback } from "react";
import { insertComments } from "redux/slice/chatComment/chatCommentSlice";
import { useAppDispatch } from "redux/hooks/hooks";
import { setMyLatestComment } from "redux/slice/chatComment/chatCommentSlice";
import { getTimeStamp } from "util/getTimeStamp";
import { generateUUID } from "util/generateUUID";
import { SearchParams } from "util/searchParams";
import { getRandomColorByString } from "util/getRandomColorByString";
import ReconnectingWebSocket from "reconnecting-websocket";

export type SendProps = {
  username: string;
  comment: string;
  isModerator: boolean;
};

type Message = {
  badge_url: string;
  comment_id: string;
  is_moderator: boolean;
  message: string;
  timestamp: number;
  user_id: string;
  username: string;
};

const useWebSocket = (url: string) => {
  const [error, setIsError] = useState<boolean | null>(null);
  const dispatch = useAppDispatch();
  const socket = useRef<ReconnectingWebSocket | null>(null);
  useEffect(() => {
    // 接続が切れた時は再接続する
    const options = {
      connectionTimeout: 5000,
    };
    socket.current = new ReconnectingWebSocket(url, [], options);

    socket.current.addEventListener("open", event => {
      setIsError(false);
    });

    socket.current.addEventListener("message", event => {
      const data: Message = JSON.parse(event.data);
      const timestamp = getTimeStamp(data.timestamp);
      const color = getRandomColorByString(data.username);
      const badge =
        data.badge_url !== undefined ? { badgeUrl: data.badge_url } : {};
      dispatch(
        insertComments({
          commentId: data.comment_id,
          username: data.username,
          comment: data.message,
          timestamp: timestamp,
          color: color,
          isModerator: data.is_moderator,
          ...badge,
        })
      );
    });

    socket.current.addEventListener("error", event => {
      setIsError(true);
      console.error("WebSocket error observed:", event);
    });

    return () => {
      if (!socket.current) return;
      console.log("WebSocket close");
      socket.current.close();
    };
    //eslint-disable-next-line
  }, []);

  const send = useCallback(({ username, comment, isModerator }: SendProps) => {
    if (!socket.current) return;
    const commentId = generateUUID();
    const payload = JSON.stringify({
      comment_id: commentId,
      username: username,
      message: comment,
      user_id: SearchParams.getParam("uid") || "null",
      is_moderator: isModerator,
    });
    socket.current.send(payload);
    dispatch(
      setMyLatestComment({
        commentId: commentId,
        username: username,
        comment: comment,
        isModerator: isModerator,
      })
    );
    //eslint-disable-next-line
  }, []);

  return { error, send };
};

export default useWebSocket;
