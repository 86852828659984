import { useState, useEffect } from "react";
import { Emoji } from "redux/slice/emoji/emojiSlice";

const useCreateEmojiMap = (emojiData: Emoji[]) => {
  const [emojiMap, setEmojiMap] = useState<Map<string, Emoji> | null>(null);

  useEffect(() => {
    const arr: [string, Emoji][] = emojiData.map(v => [v.label, v]);
    const map = new Map<string, Emoji>(arr);
    setEmojiMap(map);
  }, [emojiData]);

  return emojiMap;
};

export default useCreateEmojiMap;
