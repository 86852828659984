import React from "react";
import useChatEnable from "hooks/useChatEnable";
import { SearchParams } from "util/searchParams";
import Loading from "components/Loading";
import DisableContents from "components/DisableContents";

type Props = {
  contents: React.ReactElement;
  disableContents?: React.ReactElement;
  loading?: React.ReactElement;
};
const ContentEnableCheck: React.FC<Props> = ({
  contents,
  disableContents = <DisableContents />,
  loading = <Loading />,
}) => {
  const { enable } = useChatEnable(
    `${process.env.REACT_APP_API_ENDPOINT}/rooms/${SearchParams.getParam(
      "room"
    )}/comment/enabled`
  );

  if (enable === null) return loading;
  if (!enable) return disableContents;
  return contents;
};

export default ContentEnableCheck;
