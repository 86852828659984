import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux/store/store";
import { ColorUsername } from "constants/colorUsername";

export type Comment = {
  commentId: string;
  username: string;
  comment: string;
  timestamp: string;
  color: ColorUsername;
  badgeUrl?: string;
  isModerator: boolean;
};
export type MyLatestComment = {
  commentId: string;
  username: string;
  comment: string;
  isModerator: boolean;
};

const initialState = {
  comments: [] as Comment[],
  latestCommnet: {} as Comment,
  myLatestComment: {} as MyLatestComment,
};

export const chatCommentSlice = createSlice({
  name: "chatComment",
  initialState: initialState,
  reducers: {
    setComments: (state, action: { payload: Comment[] }) => {
      const comments = action.payload;
      state.comments = comments;
      if (comments.length > 0) {
        state.latestCommnet = comments[comments.length - 1];
      }
    },
    setMyLatestComment: (state, action: { payload: MyLatestComment }) => {
      const comment = {
        commentId: action.payload.commentId,
        username: action.payload.username,
        comment: action.payload.comment,
        isModerator: action.payload.isModerator,
      };
      state.myLatestComment = comment;
    },
    insertComments: (state, action: { payload: Comment }) => {
      const comment = {
        ...action.payload,
      };
      state.comments.push(comment);
      state.latestCommnet = comment;
    },
  },
});

export const { insertComments, setComments, setMyLatestComment } =
  chatCommentSlice.actions;
export const selectComments = (state: RootState) => state.chatComment.comments;
export const selectLatestComment = (state: RootState) =>
  state.chatComment.latestCommnet;
export const selectMyLatestComment = (state: RootState) =>
  state.chatComment.myLatestComment;

export default chatCommentSlice.reducer;
