import { createTheme } from "@material-ui/core/styles";
import { SearchParams } from "util/searchParams";
import { theme as myTheme } from "constants/theme";

const keys = Object.keys(myTheme);
const themeParam = SearchParams.getParam("theme")
  ? SearchParams.getParam("theme")!
  : "def";
const currentTheme = (
  keys.indexOf(themeParam) !== -1 ? themeParam : "def"
) as keyof typeof myTheme;

export const theme = createTheme(myTheme[currentTheme]);
